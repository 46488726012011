
            @import "src/assets/sass/index.scss";
          
.cw-loading {
	transform: translate(-50%, -50%);
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100px;
	height: 100px;
}

.cw-loading p {
	color: rgb(195, 186, 186);
}

.cw-loading div,
.cw-loading div:before,
.cw-loading div:after {
	position: absolute;
	border: .3rem solid transparent;
	border-top: .3rem solid rgb(0, 0, 255);
	border-radius: 50%;
	content: '';
    @include animation(rotate linear infinite);
}

.cw-loading div {
	height: 100px;
	width: 100px;
	animation-duration: 1.05s
}

.cw-loading div:before {
	height: 75px;
	width: 75px;
	top: 10px;
	left: 10px;
	border-top: 3px solid rgb(0, 0, 255);
	animation-duration: 10s;
}

.cw-loading div:after {
	height: 50px;
	width: 50px;
	top: 22px;
	left: 22px;
	border-top: 3px solid rgb(0, 0, 255);
	animation-duration: 4s
}

.cw-loading.cw-elliman div,
.cw-loading.cw-elliman div:before,
.cw-loading.cw-elliman div:after,
.cw-loading.cw-elliman div:before,
.cw-loading.cw-elliman div:after {
	border-top: 3px solid #009cbd
}

.subscribe-wizard .cw-loading-wrap {
	position: relative;
	min-height: 500px
}

@keyframes rotate {
    from { transform: rotateZ(360deg); }
    to { transform: rotateZ(0deg); }
}

.cw-loading-text {
	@include max-width(520px);
    padding: 2rem;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: $font-16;

    @include mobile {
        top: 64%;
    }
}