
            @import "src/assets/sass/index.scss";
          
.navigation {
    background-color: rgb(20, 26, 33);
    min-width: 28rem;
    width: 28rem;
    height: 100vh;
    border-right: .1rem solid rgb(209, 209, 209);
    position: sticky;
    overflow: hidden;
    overflow-y: scroll;
    top: 0;
    left: 0;

    &::-webkit-scrollbar {
        width: .4rem;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background: rgb(241, 241, 241); 
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgb(136, 136, 136); 
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: rgb(85, 85, 85); 
    }

    &__logo {
        @include max-width(18rem);
        margin: 3rem auto 2rem auto;
    
        img {
            @include max-width(100%);
        }
    }

    &__container {
        @include max-width(calc(100% - 4rem));
        @include x-display-flex;
        @include x-flex-direction(column);
        justify-content: space-between;
        height: 88vh;
        position: sticky;
        top: 0;
        margin: 0 auto;
        padding-top: 2rem;
        border-top: 1px solid rgba(255, 255, 255, 0.3);
    }

    &__list {
        @include x-display-flex;
        @include x-flex-direction(column);
        gap: 1rem;
        list-style: none;
    }

    &__account {
        @include x-display-flex;
        @include x-align-items(center);
        gap: 1rem;
        border-top: 1px solid rgb(209, 209, 209);

        padding: 0 {bottom: 2rem; top: 2rem;};

        & > p {
            color: white; 
        }

        a {
            @include x-display-flex;
            @include x-align-items(center);
            gap: .4rem;
            cursor: pointer;
            font-size: $font-12;
        }
    }

    &__profile {
        @include x-display-flex;
        @include x-align-items(center);
        justify-content: center;

        @include square(5rem);
        @include rounded(50%);
        padding: .2rem;
        background: conic-gradient(#00A76F, #FFAB00, #00A76F);
        border: .2rem solid conic-gradient(#00A76F, #FFAB00, #00A76F);
        mask: linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px) content-box xor, linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px);
    }

    &__circle {
        @include rounded(50%);
        background-color: $color-white;
        padding: .3rem;
    }
}