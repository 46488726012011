
            @import "src/assets/sass/index.scss";
          
.layout {
    @include max-width(calc(100vw - 2rem));
    @include x-display-flex;
    justify-content: center;
    height: 100vh;
    gap: 1rem;

    padding: 1rem;
    
    &__background {
        @include rounded($medium-border-radius);
        @include x-flex(1 0 60%);

        position: relative;
        background-image: url('../../public/images/myhomeisyours-background.jpg');
        background-size: cover;
        height: 100%;

        @include mobile {
            display: none;
        }
    }

    &__lines {
        position: absolute;
        bottom: 4rem;
        left: 2rem;
    }

    &__heading {
        color: $color-white;
        font-size: 8rem;
        font-weight: $weight-medium;
        font-family: 'Noto Serif', sans-serif;

        @media screen and (max-width: 119.9rem) {
            font-size: 5rem;
        }
    }

    &__text {
        font-size: $font-20;
        color: rgb(187, 185, 198);
        font-family: 'Noto Serif', sans-serif;
        
        @media screen and (max-width: 119.9rem) {
            font-size: $font-16;
        }
    }

    &__version {
        position: absolute;
        top: 2rem;
        right: 2rem;
        font-size: 6rem;
        color: $color-white;
        font-weight: $weight-medium;
    }

    &__content {
        @include max-width(100%);
        @include x-display-flex;
        @include x-align-items(center);
        justify-content: center;

        @include mobile {
            @include x-flex(1);
            padding: 1rem;
        }
    }
}