
            @import "src/assets/sass/index.scss";
          
.loader {
    width: 2.2rem;
    height: 2.2rem;
    border: .2rem solid rgb(183, 190, 199);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}