
            @import "src/assets/sass/index.scss";
          
$main-buttons: (
  main: $color-slate-gray,
  dark: $color-dark,
  danger: $delete-background-color,
  gold: rgb(169,126,83),
  slate-grey: $color-slate-gray,
  dark-grey: rgb(28, 37, 46),
  dark-purple: rgb(15, 5, 41),
  blue: $main-blue-color,
  brown: rgb(63, 63, 63)
);

@each $item, $value in $main-buttons {
    .btn-#{"" + $item} {
      @include button($background: $value);

      &--full {
        @include max-width(100%);
      }
    }
}