
            @import "src/assets/sass/index.scss";
          
.table {
    width: 100%;
    table-layout: fixed;

    &__header {
        @include x-display-flex;
        @include x-align-items(center);
        gap: 1rem;
        
        padding: 0 1rem;
        margin: 1rem .6rem 2rem;

        h2 {
            font-size: $font-24;
            font-weight: $weight-medium;
        }
    }

    &__table {
        @include max-width(100%);
    }

    &__head {
        th {
            background-color: rgb(244, 246, 248);
            font-size: $font-14;
            padding: 1.6rem;
            text-align: left;
            color: $pallete-text-primary;
            font-weight: $weight-medium;
        }
    }

    &__body {
        tr {
            @include transition(all .2s);

            &:hover {
                background-color: rgba(145, 158, 171, 0.08);
            }

            td {
                border-bottom: .1rem solid rgba(145 158 171 / 0.2);
            }
        }

        td {
            font-size: 1.5rem;
            padding: 1.2rem 1.6rem;
        }
    }

    &__button {
        @include rounded-button($font-size: $font-20);
    }
}