
            @import "src/assets/sass/index.scss";
          
.pagination {
    @include x-display-flex;
    @include x-align-items(center);
    @include x-flex-wrap(wrap);
    gap: .4rem;

    margin: 2rem 0;

    &__link {
        @include rounded-button($font-size: $font-16, $width: 3rem);

        &--active,
        &--active:hover {
            background-color: $pallete-text-primary;
            color: $color-white;
        }
    }
}