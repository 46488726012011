
            @import "src/assets/sass/index.scss";
          
.box {
    @include max-width(60rem);

    &__container {
        @include x-display-flex;
        @include x-flex-direction(column);
        @include x-align-items(center);
        gap: 2rem;
    }

    &__image {
        @include max-width(24rem);

        img {
            @include max-width(100%);
        }
    }

    &__heading {
        font-size: $font-30;
        font-weight: $weight-medium;
        text-align: center;
    }

    &__text {
        font-size: $font-20;
        font-weight: $normal-weight;
        text-align: center;
    }
}