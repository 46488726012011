
            @import "src/assets/sass/index.scss";
          
.wrapper {
    @include x-flex(1);
    margin: 0 {top: 6rem};
    padding: 2rem;

    @include mobile {
        padding: 4rem 1rem;
    }
}