
            @import "src/assets/sass/index.scss";
          
.infocard {
    &--inline {
        @include x-display-flex;
        @include x-align-items(center);
        gap: .6rem;
    }

    &__label {
        color: rgb(99, 115, 129);
        font-size: $font-16;
    }

    &:not(&--inline) {
        .infocard__label {
            margin: 0 {bottom: .8rem};
        }
    }

    &__items {
        @include x-display-flex;
        @include x-flex-wrap(wrap);
        gap: 1rem;
    
        margin: 0 {top: 1rem};
    }

    &__tag {
        @include rounded($large-border-radius);
        padding: .4rem 1.8rem;
        background-color: rgba($main-blue-color, .1);
        color: $main-blue-color;
    }
}