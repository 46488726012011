
            @import "src/assets/sass/index.scss";
          
$roles: (
    user: $color-dark,
    moderator: $main-orange-color,
    admin: rgb(101, 19, 59),
    masteradmin: $main-blue-color
);

.dropdown {
    @include rounded($large-border-radius);
    @include box-shadow($main-box-shadow);
    @include max-width(30rem);

    position: fixed;
    right: 1rem;
    top: 2rem;
    padding: 2rem;
    z-index: 12;

    background-color: $color-white;

    &__header {
        @include x-display-flex;
        @include x-flex-direction(column);
        @include x-align-items(center);
        gap: .6rem;
    }

    &__name {
        p {
            font-size: $font-20;
            color: $color-dark;
        }
    }

    &__image {
        img {
            @include circle(5rem);
            object-fit: cover;
        }
    }

    &__list {
        @include max-width(100%);
        margin: 2rem 0;
    }

    &__link {
        @include x-display-flex;
        @include x-align-items(center);
        gap: 1rem;

        @include max-width(100%);
        @include rounded($large-border-radius);
        @include transition($main-transition);
        padding: 1rem 2rem;
        font-size: $font-16;
        cursor: pointer;
        color: $color-black;

        &:hover {
            background-color: rgba($color-dark, 10%);
        }
    }

    &__status {
        @include rounded($medium-border-radius);
        font-size: $font-14;
        padding: .4rem 1.6rem;

        @each $item, $value in $roles {
            &--#{'' + $item} {
                background-color: rgba($value, .1);
                color: $value;
            }
        }
    }
}