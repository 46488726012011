
            @import "src/assets/sass/index.scss";
          
$main-buttons: (
  main: $color-slate-gray,
  dark: $color-dark,
  danger: $delete-background-color,
  gold: rgb(169,126,83),
  slate-grey: $color-slate-gray,
  dark-grey: rgb(28, 37, 46),
  dark-purple: rgb(15, 5, 41),
  blue: $main-blue-color,
  sky-blue: rgb(13, 99, 242),
  brown: rgb(63, 63, 63),
  success: $main-green-color,
  orange: rgb(253, 138, 51),
  light-blue: rgb(83 141 215)
);

@each $item, $value in $main-buttons {
    .btn-#{"" + $item} {
      @include rounded-button($color: $value, $width: 3.6rem, $font-size: 1.8rem);
      position: relative;

        &__tooltip {
            @include transition(all .2s);
            @include x-display-flex;
            @include x-align-items(center);
            justify-content: center;
            opacity: 0;

            @include rounded(.6rem);
            background-color: rgba($color-dark, .8);
            color: $color-white;
            font-size: 1.3rem;
            padding: .6rem .4rem;
            text-align: center;
            height: 2.6rem;

            position: absolute;
            top: -100%;
            left: 50%;
            transform: translateX(-50%) scale(0.9);
            z-index: 99;
        }

        &:hover {
            .btn-#{"" + $item}__tooltip {
                opacity: 1;
                transform: translateX(-50%) scale(1);
            }
        }
    }
}