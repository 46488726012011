
            @import "src/assets/sass/index.scss";
          
.input {
    @include max-width(100%);
    position: relative;

    input {
        @include primary-input($padding: .8rem 1.6rem .3rem 5rem);

        &:is(:focus) {
            outline: none;
        }

        &.is-dirty {
            // outline: none;
            @include box-shadow(0 2px 10px 0 rgba($delete-background-color, 0.2));
            border: .1rem solid rgba($delete-background-color, .4);
    
            & ~ span.placeholder {
                color: $delete-background-color;
                top: 2.8rem;
            }
    
            &:is(:focus, :focus-visible, :not(:placeholder-shown)) {
                outline: none;
                border: .1rem solid $delete-background-color;
            }
        }
    }

    &__icon {
        position: absolute;
        top: 1.6rem;
        left: 2rem;

        svg {
            color: rgb(112, 113, 123);
            font-size: $font-16;
        }
    }

    &__placeholder {
        @include transition($main-transition);
        font-size: $font-16;
        font-weight: $large-weight;
        position: absolute;
        top: 1.2rem;
        left: 5rem;
        color: rgb(112, 113, 123);
        pointer-events: none;
    }

    input:is(:focus) ~ &__placeholder,
    input:not(:placeholder-shown) ~ &__placeholder {
        top: .4rem;
        left: 4.8rem;
        transform: none;
        font-size: $font-12;
    }  

    &__danger {
        p {
            display: block;
            margin: .4rem;
            color: $delete-background-color;
            font: {
                size: $font-14;
            }
        }
    }
}