
            @import "src/assets/sass/index.scss";
          
$colors: (
    home: rgb(110, 110, 110),
    accounts: rgb(110, 110, 110),
    createproperty: rgb(110, 110, 110),
    listings: rgb(110, 110, 110)
);

.item {
    @include transition($main-transition);
    border-radius: 1rem;
    @include x-display-flex;
    @include x-align-items(center);
    gap: .6rem;

    @include transition($main-transition);
    @include max-width(100%);
    position: relative;
    padding: 1rem 2rem;
    font-size: $font-16;
    color: rgb(182, 190, 197);

    &--active {
        background-color: rgba(7, 141, 238, .16);
        color: rgb(104, 205, 249);
        font-weight: $normal-weight;
    
        svg {
            color: rgb(104, 205, 249);
        }
    }
    

    &__icon {
        @each $item, $value in $colors {
            &--#{$item} {
                color: $value;
            }
        }
    }

    &__dropdown {
        @include max-width(100%);
        @include x-display-flex;
        @include x-flex-direction(column);
        @include x-align-items(center);
        gap: .6rem;

        @include rounded($large-border-radius);
        @include transition($main-transition);
        @include max-width(100%);
        font-size: $font-16;
        color: rgb(202, 206, 207);
    }

    &__menuicon {
        @include transition($main-transition);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 2rem;

        &--open {
            transform: translateY(-50%) rotate(180deg);
        }
    }

    &__submenu {
        @include x-display-flex;
        @include x-flex-direction(column);
        gap: .4rem;
        
        @include transition(all 300ms cubic-bezier(0.4, 0, 0.2, 1));
        @include max-width(20rem);
        margin-right: auto;
        margin-left: 2rem;
        padding-left: 2rem;
        max-height: 0;
        opacity: 0;
        position: relative;
    
        &--active {
            opacity: 1;
            max-height: 60rem;
        }

        &::before {
            content: '';
            width: .1rem;
            height: calc(100% - 2rem);
            background-color: rgb(40, 47, 55);
            position: absolute;
            left: 0;
        }
    }
}

.item:hover:not(.item--active) { 
    background-color: rgba(145 158 171 / 0.08);
    color: $color-white;
    font-weight: $normal-weight;
}

.subitem {
    @include transition($main-transition);
    @include rounded($extra-small-border-radius);
    display: block;
    padding: 1rem 1.6rem;
    color: rgb(182, 190, 197);
    font-size: $font-14;
    position: relative;

    &::after {
        content: '';
        background-image: url("../../../../public/images/line.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: 1rem;
        height: 1rem;
        position: absolute;
        opacity: 0.08;
        left: -1.98rem;
    }

    &:hover,
    &--active {
        background-color: rgba($color-white, .1);
        color: $color-white;
        font-weight: $normal-weight;
    }
}