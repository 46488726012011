
            @import "src/assets/sass/index.scss";
          
.header {
    @include max-width(100%);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;

    padding: 1rem 2rem;
    background-color: $color-white;
    border-bottom: .1rem solid rgba($color-dark, 20%);

    &__logo {
        @include max-width(24rem);

        &--icab {
            @include max-width(20rem);
        }

        img {
            @include max-width(100%);
        }

        @include mobile {
            @include max-width(16rem);
        }
    }

    &__container {
        @include max-width(152rem, center);
        @include x-display-flex;
        @include x-align-items(center);
        justify-content: space-between;
    }

    &__info {
        a {
            font-size: $font-20;
            color: $color-dark;

            @include mobile {
                font-size: $font-16;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
}