
            @import "src/assets/sass/index.scss";
          
.creator {
    @include max-width(fit-content);
    @include rounded($large-border-radius);
    @include transition($main-transition);
    position: relative;

    button {
        @include x-display-flex;
        @include x-align-items(center);
        gap: .4rem;

        color: $color-dark;
        cursor: pointer;
        border: none;
        background-color: transparent;
    }

    &:hover {
        .creator__name {
            text-decoration: underline;
        }
    }
    
    &__image {
        @include rounded(50%);
        @include square(3rem);
        object-fit: cover;
    }

    &__name {
        font-size: $font-14;
    }

    &__tooltip {
        @include transition(all .2s);
        @include x-display-flex;
        justify-content: center;
        opacity: 0;

        @include rounded(.6rem);
        background-color: rgba($color-dark, .8);
        color: $color-white;
        font-size: $font-12;
        padding: .6rem .2rem;
        text-align: center;

        position: absolute;
        top: -100%;
        left: 50%;
        transform: translateX(-50%);
    }

    &:hover {
        .creator__tooltip {
            opacity: 1;
        }
    }
}