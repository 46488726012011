
            @import "src/assets/sass/index.scss";
          
.auth {
    &__logo {
        @include max-width(30rem);
        margin: 3rem auto;

        img {
            @include max-width(100%);
        }
    }

    &__component {
        @include max-width(50rem);
    }

    &__form {
        @include x-display-flex;
        @include x-flex-direction(column);
        gap: 1rem;
    }

    &__info {
        @include x-display-flex;
        @include x-align-items(center);
        gap: .6rem;

        margin: 0 auto;

        p {
            @include themed {
                color: t('text');
            }
        }

        a {
            @extend %main-link;
            font-size: $font-16;
        }
    }

    &__indication {
        @include x-display-flex;
        @include x-flex-direction(column);
        @include x-align-items(center);
    
        margin: 0 {bottom: 2rem};
    }

    &__heading {
        font-size: 4rem;
        font-weight: $weight-medium
    }

    &__text {
        text-align: center;
    }

    &__disclaimer {
        @include max-width(fit-content);
        margin: 2rem auto;

        a {
            font-size: $font-16;
            color: $main-blue-color;
            text-align: center;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__error {
        @include rounded($medium-border-radius);
        margin: 0 {bottom: 2rem};
        padding: 1rem 2rem;
        background-color: rgba($delete-background-color, 10%);
        color: $delete-background-color;
        border: .1rem solid currentColor;
        text-align: center;
    }

    &__text {
        margin: 1rem auto 2rem auto;
    
        p {
            font-size: $font-16;
            text-align: center;
        }
    }

    &__submit {
        @include max-width(100%);
        margin: 2rem auto 0 auto;
    }
}