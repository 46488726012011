
            @import "src/assets/sass/index.scss";
          
.error {
    @include max-width(100%);

    &__container {
        @include max-width(60rem);
        margin: 6rem auto 0 auto;
    }

    &__heading {
        font-size: $font-30;
        font-weight: $weight-medium;
        text-align: center;
    }

    &__text {
        text-align: center;
    }

    &__button {
        @include button;
        margin: 2rem auto;
    }
}