
            @import "src/assets/sass/index.scss";
          
.component {
    @include rounded($medium-border-radius);
    @include box-shadow(rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px);

    background-color: $color-white;

    &--padding {
        padding: 2rem;
    }
}