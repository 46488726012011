
            @import "src/assets/sass/index.scss";
          
.checkbox-input {
    @include max-width(fit-content);
    @include transition($main-transition);
    position: relative;
    margin: 1rem 0;

    &:not(.checked):hover {
        label {
            @include box-shadow($component-box-shadow);
        }
    }

    &.checked {
        label {
            background-color: rgb(240, 251, 243);
            font-weight: $normal-weight;
            border: .1rem solid rgb(0, 150, 78);
            // @include box-shadow(0px 1px 2px rgb(0, 150, 78));
        }
    }
    
    input {
        display: none;
    }

    label {
        @include max-width(fit-content);
        @include rounded($large-border-radius);
        @include transition($main-transition);
        @include x-display-flex;
        @include x-align-items(center);
        justify-content: center;

        font-size: $font-16;
        gap: 0 $font-10;
        cursor: pointer;
        padding: 1rem 2rem;
        border: .1rem solid rgb(240, 240, 240);
    }

    span {
        @include max-width(100%);
        @include x-display-flex;
        @include x-align-items(center);
        gap: .6rem;
        font-size: $font-16;
    }

    img {
        @include max-width(2rem);
    }
}