
            @import "src/assets/sass/index.scss";
          
.navigation {
    position: fixed;
    top: 0;
    left: 28rem;
    z-index: 9;
    width: calc(100% - 28rem);
    background-color: $color-white;
    padding: .4rem 2rem;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);

    &__container{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__profile {
        @include x-display-flex;
        @include x-align-items(center);
        gap: 1rem;

        cursor: pointer;

        img {
            @include square(100%);
            object-fit: cover;
        }
    }

    &__image {
        margin: 1rem;
        position: relative;

        img {
            @include circle(4rem);
            object-fit: cover;
        }
    }

    &__info {
        p {
            font-size: $font-16;
            color: $color-dark;
        }
    }

    &__actions {
        svg:nth-child(2){
            animation: loading 10s linear infinite;
            @keyframes loading {
             0% { 
               transform: rotate(0); 
             }
             100% { 
               transform: rotate(360deg);
             }
            }
           
        }

        svg {
            height: 2.4rem;
            color: rgb(99, 115, 129);
            margin: 0 15px 0 0;
        }
    }

    &__active {
        @include circle(1.4rem);
        border: .1rem solid $color-white;
        background: $main-green-color;
        position: absolute;
        right: 0;
        bottom: 0;
    }
}