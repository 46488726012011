
            @import "src/assets/sass/index.scss";
          
$types: (
    info: (
        background: rgb(204, 229, 255),
        color: rgb(0, 64, 133)
    )
);

.disclaimer {
    @include max-width(fit-content);
    @include x-display-flex;
    @include x-align-items(center);
    gap: 1rem;

    @include rounded($medium-border-radius);
    padding: 2rem;
    margin: 2rem 0;

    @each $item, $value in $types {
        &--#{"" + $item} {
            background-color: map-get($value, background);
            color: map-get($value, color);
        }
    }

    p {
        &:first-child {
            font-size: $font-16;
        }
    }

    svg {
        font-size: $font-20;
    }
}