
            @import "src/assets/sass/index.scss";
          
.error {
    @include x-display-flex;
    justify-content: center;
    margin: 0 auto;

    &__container {
        @include max-width(68rem);
        margin: 4rem auto;
    }

    &__heading {
        font-size: $font-30;
        font-weight: $weight-medium;
        text-align: center;
    }

    &__text {
        text-align: center;
    }

    &__btn {
        @include button;
        margin: 2rem auto;
    }
}