
            @import "src/assets/sass/index.scss";
          
.modal {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(.4rem);
    width: 100%;
    height: 100%;
    z-index: 999;

    &__container {
        @include rounded($medium-border-radius);
        @include max-width(fit-content);
        background-color: $color-white;
        padding: 2rem;

        position: relative;
        height: fit-content;
        max-height: calc(100vh - 16rem);
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 0rem;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: rgb(241, 241, 241); 
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgb(136, 136, 136); 
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: rgb(85, 85, 85); 
        }

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include mobile {
            @include max-width(calc(100% - 2rem), center);
        }
    }
}