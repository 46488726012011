
            @import "src/assets/sass/index.scss";
          
.nav {
    @include max-width(100%);
    margin: 0 {bottom: 2rem};

    &__container {
        @include x-display-flex;
        @include x-align-items(center);
        justify-content: space-between;
        gap: 2rem;
    }

    &__heading {
        display: block;
        margin: 0 {bottom: 1rem};
        font-size: $font-24;
        font-weight: $normal-weight;
    }

    &__breadcrumbs {
        @include x-display-flex;
        @include x-align-items(center);
        gap: 1.6rem;

        a {
            color: $color-dark;
            font-size: $font-14;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__circle {
        @include square(.4rem);
        @include rounded(50%);
        background-color: rgb(145, 158, 171);
    }

    &__item {
        &--disabled {
            a {
                pointer-events: none;
                color: rgb(145, 158, 171);
            }
        }
    }

    &__actions {
        a {
            @include button;
        }
    }
}