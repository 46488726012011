
            @import "src/assets/sass/index.scss";
          
.info {
    &__heading {
        font-size: $font-20;
        font-weight: $normal-weight;
    }

    &__container {
        padding: 3rem 1rem {top: 0};
    }

    &__landlords {
        padding: 0 {bottom: 1rem};
        margin: 0 {bottom: 1rem};
        
        &:not(:last-child) {
            border-bottom: .1rem solid rgba($color-black, .2);
        }
    }

    &__link {
        @extend %main-link;
    }

    &__info {
        @include x-display-flex;
        @include x-flex-direction(column);
        gap: 1rem;

        padding: 1rem 0;

        &--small * {
            font-size: $font-14;
        }

        &--small a {
            font-size: $font-16 !important;
        }
    }

    &__inline {
        @include x-flex-direction(row);
        @include x-align-items(flex-start);
        gap: 4rem;

        & > div {
            width: 20rem;
        }
    }

    &__name {
        @include x-display-flex;
        @include x-align-items(center);
        gap: 1rem;

        a {
            @extend %main-link;
            font-size: 1.8rem;
            font-weight: $normal-weight;
        }

        h2 {
            font-size: 1.8rem;
            font-weight: $normal-weight;
        }
    }

    &__metadata {
        color: rgb(99, 115, 129);
        font-size: $font-14;
    }

    &__property {
        @include x-display-flex;
        @include x-align-items(center);
        gap: 1rem;
    }

    &__interface {
        @include x-display-flex;
        justify-content: space-between;
        gap: 4rem;
    }
}